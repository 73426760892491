// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stopwatch {
  margin: auto;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
  width: 400px;
  height: 180px;
  position: relative;
  border-radius: 10px; }

.stopwatch h1 {
  font-size: 1.5rem;
  margin: auto;
  margin-top: 20px;
  padding: 10px;
  height: -moz-fit-content;
  height: fit-content;
  color: #333333; }

.stopwatch .time {
  font-size: 3rem;
  margin: 10px;
  font-family: monospace; }

.stopwatch .time span {
  font-size: 1.5rem; }

.stopwatch button {
  border: none;
  border-radius: 5px;
  padding: 10px;
  background-color: #ffb923;
  margin: 0px 5px;
  cursor: pointer; }

.stopwatch button:hover {
  background-color: #e7a619; }
`, "",{"version":3,"sources":["webpack://./src/styles/Timer.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACI,YAAY;EAGZ,gDAA6C;EAC7C,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,mBAAmB,EAAA;;AAEvB;EACI,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,wBAAmB;EAAnB,mBAAmB;EACnB,cCV0B,EAAA;;ADY9B;EACI,eAAe;EACf,YAAY;EACZ,sBAAsB,EAAA;;AAE1B;EACI,iBAAiB,EAAA;;AAErB;EACI,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,yBCjBwB;EDkBxB,eAAe;EACf,eAAe,EAAA;;AAEnB;EACI,yBCrB0B,EAAA","sourcesContent":["@import './variables.scss';\r\n\r\n.stopwatch{\r\n    margin: auto;\r\n    -webkit-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.75);\r\n    -moz-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.75);\r\n    box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.75);\r\n    width: 400px;\r\n    height: 180px;\r\n    position: relative;\r\n    border-radius: 10px;\r\n}\r\n.stopwatch h1{\r\n    font-size: 1.5rem;\r\n    margin: auto;\r\n    margin-top: 20px;\r\n    padding: 10px;\r\n    height: fit-content;\r\n    color: $text-color-1;\r\n}\r\n.stopwatch .time{\r\n    font-size: 3rem;\r\n    margin: 10px;\r\n    font-family: monospace;\r\n}\r\n.stopwatch .time span{\r\n    font-size: 1.5rem;\r\n}\r\n.stopwatch button{\r\n    border: none;\r\n    border-radius: 5px;\r\n    padding: 10px;\r\n    background-color: $button-2;\r\n    margin: 0px 5px;\r\n    cursor: pointer;\r\n}\r\n.stopwatch button:hover{\r\n    background-color: $button-2-h;\r\n}","$color-1: #eed443;\r\n$color-2: red;\r\n$color-3: green;\r\n$color-4: rgb(143, 143, 143);\r\n\r\n$background-color-1: #eee;\r\n$background-color-2: #d3d3d3;\r\n\r\n$text-color-1: rgb(51, 51, 51);\r\n$text-color-2: #aaaaaa;\r\n$text-color-3: rgb(102, 102, 102);\r\n$text-color-4: white;\r\n\r\n$button-1: rgb(245, 32, 32);\r\n$button-1-h: rgb(221, 20, 20);\r\n$button-2: rgb(255, 185, 35);\r\n$button-2-h: rgb(231, 166, 25);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
